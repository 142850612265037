<template>
    <article>
        <h1>藍燈魚智能檢索平臺用戶協議</h1>
        <p>藍燈魚智能檢索平臺（以下簡稱“本網站”）依據《藍燈魚智能檢索平臺用戶協議》（以下簡稱“本協議”）的規定提供服務，本協議具有合同效力。請您務必審慎閱讀、充分理解各條款內容，特別是免除或者限制責任的條款、法律適用和爭議解決條款。免除或者限制責任的條款將以粗體下劃線標識，您應重點閱讀。 
            若您已經註冊為本網站用戶，即表示您已充分閱讀且自願接受本協議的條款約束。本網站有權隨時變更本協議並在本網站上予以公告。經修訂的條款壹經在本網站的公布後，立即自動生效。如您不同意相關變更，有權停止使用本網站。如您繼續使用本網站，則視為接受本網站對本協議做出的修改。本協議內容包括協議正文及所有藍燈魚智能檢索平臺已經發布的各類規則。相關規則為本協議不可分割的壹部分，與本協議正文具有同等法律效力。
        </p>
        <h2>1. 用戶資格</h2>
        <p>1.1 用戶應為具備完全民事權利能力且民事行為能力與所從事的民事行為相適應的自然人、法人或其他組織。若您不具備前述主體資格，請勿使用服務，否則您及您的監護人應承擔因此而導致的壹切後果，且本網站有權註銷（永久凍結）您的賬戶，並向您及您的監護人索償。如您代表壹家公司或其他法律主體在本網站登記，則您聲明和保證，您有權使該公司或該主體受本協議的約束。</p>
        <p>1.2 用戶在註冊時需提供真實姓名（名稱）及聯系電話。</p>
        <p>1.3 本網站用戶須承諾遵守法律法規、社會主義制度、國家利益、公民合法權益、公共秩序、社會道德風尚和信息真實性。</p>
        <h2>2. 用戶的權利和義務</h2>
        <p>2.1 用戶有權根據本協議及本網站發布的相關規則，利用本網站發布需求信息、查詢用戶信息、發布作品信息、參與需求咨詢、參與提供服務，在本網站發布相關產品信息，參加本網站的有關活動及有權享受本網站提供的其他有關資訊及信息服務；</p>
        <p>2.2 用戶須自行保管自己的用戶賬號和密碼，且須對在用戶賬號密碼下發生的所有活動（包括但不限於發布需求信息、網上點擊同意各類協議、規則、參與需求投標或等）承擔責任。用戶有權根據需要更改登錄和賬戶密碼。因用戶的過錯導致的任何損失由用戶自行承擔，該過錯包括但不限於：不按照交易提示操作，未及時進行交易操作，遺忘或泄漏密碼，密碼被他人破解，用戶使用的計算機被他人侵入。</p>
        <p>2.3 用戶應當向本網站提供真實準確的註冊信息，包括但不限於真實姓名、身份證認證、聯系電話、地址、郵政編碼等。保證本網站可以通過上述聯系方式與自己進行聯系。同時，用戶也應當在相關資料實際變更時及時更新有關註冊資料；若因用戶提供錯誤、虛假、過時或不完整的資料而導致的任何損失，本網站不承擔任何責任。</p>
        <p>2.4 用戶在本網站註冊的賬號名稱、頭像及簡介，不得有下列情形：</p>
        <ul>
            <li>違反憲法或法律法規的；</li>
            <li>危害國家安全，泄露國家秘密，顛覆國家政權，破壞國家統壹的；
            </li>
            <li>損害國家榮譽和利益的，損害公共利益的；
            </li>
            <li>煽動民族仇恨、民族歧視，破壞民族團結的；
            </li>
            <li>破壞國家宗教政策，宣揚邪教和封建迷信的；
            </li>
            <li>散布謠言，擾亂社會秩序，破壞社會穩定的；
            </li>
            <li>
                散布淫穢、色情、賭博、暴力、兇殺、恐怖或者教唆犯罪的；
            </li>
            <li>侮辱或者誹謗他人，侵範他人合法權益的；
            </li>
            <li>含有法律、行政法規禁止的其他內容的。
            </li>
        </ul>
        <p>2.5 用戶不得以虛假信息註冊賬號；</p>
        <p>2.6 用戶不得冒用其關聯機構或社會名人的名稱註冊賬號。如因用戶授權的被委托人故意或者過失導致的賬戶冒用而引發的責任和相關損失，由委托人承擔。因此給本站帶來的損害，委托人應與實際使用人承擔連帶責任。</p>
        <p>2.7 用戶不得以任何形式擅自轉讓或授權他人使用自己在本網站的帳號；
        </p>
        <p>2.8 用戶有義務確保在本網站上發布的需求信息真實、準確，無誤導性；</p>
        <p>2.9 用戶在本網站上發布的需求或服務信息，不得違反國家法律、法規、行政規章的規定、不得違背社會公共利益或公共道德、不得侵犯他人知識產權或其他合法權益的信息、不得違反本網站的相關規定；
        </p>
        <p>2.10 用戶在本網站交易中應當遵守誠實信用原則，不得幹預或操縱信息發布，不得擾亂網上交易秩序或采取其他不正當競爭手段，不得從事與網上交易無關的不當行為；</p>
        <p>2.11 用戶不應采取不正當手段（包括但不限於虛假訂單、互換好評等方式）提高自身或他人信用度，惡意差評其他用戶，或降低其他用戶信用度；
        </p>
        <p>2.12 用戶不得違反《銀行卡業務管理辦法》使用銀行卡，或利用信用卡套取現金；</p>
        <p>2.13 用戶承諾自己在使用本網站實施的所有行為遵守法律、法規、行政規章和本網站的相關規定以及各種社會公共利益或公共道德。如違反上述規定導致任何法律後果，用戶將以自己的名義獨立承擔相應的法律責任；
        </p>
        <p>2.14 用戶在本網站網上交易過程中如與其他用戶因交易產生糾紛，可以請求本網站從中予以協調處理。用戶如發現其他用戶有違法或違反本協議的行為，可以向本網站舉報；
        </p>
        <p>2.15 除本網站另有規定外，用戶應當自行承擔因交易產生的相關費用，並依法納稅；
        </p>
        <p>2.16 未經本網站書面允許，用戶不得將本網站的任何資料以及在交易平臺上所展示的任何信息於其他商業性用途（包括但不限於以復制、修改、翻譯等形式制作、分發或公開展示衍生作品）；
        </p>
        <p>2.17 用戶不得使用以下方式登錄網站或破壞網站所提供的服務：</p>
        <ul>
            <li>以任何機器人軟件、蜘蛛軟件、爬蟲軟件、刷屏軟件或其它自動方式訪問或登錄本網站；
            </li>
            <li>對本網站部結構造成或可能造成不合理或不合比例的重大負荷的行為；
            </li>
            <li>
                幹擾或試圖幹擾網站的正常工作或網站上進行的任何活動。
            </li>
        </ul>
        <p>2.18 用戶同意接收來自本網站的信息，包括但不限於活動信息、交易信息、促銷信息等。
        </p>
        <p>2.19 用戶了解並同意，如用戶在本網站成功註冊，並成功完成實名認證，在本網站交易中提現等操作都需要通過相關支付賬戶進行。
        </p>
        <p>2.20 用戶了解並同意此智能檢索平臺協議，明確並允許智能檢索平臺及藍燈魚向其註冊智能檢索平臺的郵箱發送信息，包括但不限於提醒郵件、推廣郵件等。
        </p>
        <h2>3. 本網站的權利和義務</h2>
        <p>
            3.1 本網站應根據您選擇的服務以及交納款項的情況向您提供合格的智能檢索服務；
        </p>
        <p>
            3.2 本網站有義務在現有技術水平的基礎上努力確保整個網上交流平臺的正常運行，盡力避免服務中斷或將中斷時間限制在最短時間內，保證用戶網上交流活動的順利進行；
        </p>
        <p>3.3 本網站有義務對用戶使用本網站時遇到的有關註冊或交易問題及反映的情況做出及時的回復；</p>
        <p>3.4 本網站有權對用戶的註冊資料進行審查，對存在任何問題或懷疑的註冊資料，本網站有權發出通知詢問用戶並要求用戶做出解釋、改正；</p>
        <p>3.5 用戶因在本網站網上交易與其他用戶產生糾紛的，用戶將糾紛告知本網站，或本網站知悉糾紛情況的，本網站有權通過電子郵件及電話聯系向糾紛雙方了解糾紛情況，並將所了解的情況通過電子郵件互相通知對方；如用戶通過司法機關依照法定程序要求本網站提供相關資料，本網站將積極配合並提供有關資料；
        </p>
        <p>3.6 因互聯網信息平臺的特殊性，本網站沒有義務對用戶的交易行為以及與交易有關的其他事項進行事先審查，但如發生以下情形，本網站有權無需征得用戶的同意而限制用戶的活動、向用戶核實有關資料、發出警告通知或中止及拒絕向該用戶提供服務：
        </p>
        <ul>
            <li>用戶以非自然人名義進行認證之後,認證主體自行註銷或者經有權機關吊銷或撤銷的；
            </li>
            <li>用戶違反本協議相關規則或修行後條款及規則的；
            </li>
            <li>用戶或其他第三方通知本網站，認為某個用戶或具體交易事項存在違法或不當行為，並提供相關證據，而本網站無法聯系到該用戶或無法驗證該用戶向本網站提供的任何資料；
            </li>
            <li>用戶或其他第三方通知本網站，認為某個用戶或具體交易事項存在違法或不當行為，並提供相關證據。本網站以普通非專業人員的知識水平標準對相關內容進行判別，認為該內容或行為會導致第三方或本網站的合法權益受損；</li>
        </ul>
        <p>3.7 根據國家法律、法規、行政規章規定、本協議的內容和本網站所掌握的事實依據，可以認定該用戶存在違法或違反本協議行為以及在本網站交易平臺上的其他不當行為，本網站有權無需征得用戶的同意在本網站交易平臺及所在網站上以網絡發布形式公布該用戶的不當行為，並有權隨時刪除相關信息、終止服務提供；
        </p>
        <p>3.8 本網站依據本協議及相關規則，可以凍結、使用、先行賠付、退款並處置用戶在本網站賬戶內的資金。因違規而被封號的用戶賬戶中的資金在按照規定進行處置後尚有余額的，該用戶可申請提現；
        </p>
        <p>3.9 本網站有權在不通知用戶的前提下，刪除或采取其他限制性措施處理下列信息：包括但不限於以規避費用為目的；以炒作為目的；存在惡意欺詐或內容虛假；與網上交易無關或不是以交易為目的；存在惡意競價或其他試圖擾亂正常交易秩序因素；違反公共利益或可能嚴重損害本網站和其他用戶合法利益；
        </p>
        <p>3.10 本網站承諾對您資料采取對外保密措施，不向第三方披露您資料，不授權第三方使用您資料，除非：
        </p>
        <ul>
            <li>依據本協議條款或者您與本網站之間其他服務協議、合同等規定可以提供；
            </li>
            <li>依據法律法規的規定應當提供；</li>
            <li>行政、司法等職權部門要求本網站提供；</li>
            <li>您同意本網站向第三方提供；</li>
            <li>本網站解決舉報事件、提起訴訟而提交的；</li>
            <li>本網站為防止嚴重違法行為或涉嫌犯罪行為發生而采取必要合理行動所必須提交的；</li>
            <li>本網站為向您提供產品、服務、信息而向第三方提供的，包括本網站通過第三方向您提供產品、服務、咨詢。</li>
        </ul>
        <p>3.11 本網站及本網站關聯公司所有網站有權使用您的資料和信息作為推廣宣傳之用，但不會對外泄露賬號內容。
        </p>
        <h2>4. 服務的中止和終止</h2>
        <p>4.1 本網站可自行全權決定以任何理由（包括但不限於本網站認為用戶已違反本協議及相關規則，或用戶在超過6個月內未登錄本網站等）終止對用戶的服務，但會在兩年內保存用戶在本網站的全部資料（包括但不限於用戶信息、產品信息、交易信息等）。同時本網站可自行全權決定，在發出通知或不發出通知的情況下，隨時停止提供全部或部分服務。服務終止後，本網站沒有義務為用戶保留原賬戶中或與之相關的任何信息，或轉發任何未曾閱讀或發送的信息給用戶或第三方；
        </p>
        <p>4.2 若用戶申請終止本網站服務，需經本網站審核同意，方可解除與本網站的協議關系，但本網站仍保留下列權利：
        </p>
        <ul>
            <li>本網站有權在法律、法規、行政規章規定的時間內保留該用戶的資料,包括但不限於以前的用戶資料、交易記錄等;
            </li>
            <li>
                若終止服務之前，該用戶在本網站交易平臺上存在違法行為或違反本協議的行為，本網站仍可行使本協議所規定的權利。
            </li>
        </ul>
        <p>4.3 用戶存在下列情況，本網站可以終止向該用戶提供服務：</p>
        <ul>
            <li>在用戶違反本協議及相關規則規定時，本網站有權終止向該用戶提供服務。本網站將在中斷服務時通知用戶。但該用戶在被本網站終止提供服務後，再壹次直接或間接或以他人名義註冊為本網站用戶的，本網站有權再次單方面終止為該用戶提供服務；</li>
            <li>本網站發現用戶註冊資料中主要內容是虛假的，本網站有權隨時終止為該用戶提供服務；
            </li>
            <li>本協議終止或更新時，用戶未確認新的協議的；
            </li>
            <li>其它本網站認為需終止服務的情況。
            </li>
        </ul>
        <h2>5. 本網站的責任範圍</h2>
        <p>5.1 本網站不能隨時預見到任何技術上的問題或其他困難。該等困難可能會導致數據損失或其他服務中斷。本網站是在現有技術基礎上提供的服務。本網站不保證以下事項∶
        </p>
        <ul>
            <li>本網站將符合所有用戶的要求；
            </li>
            <li>本網站提供服務的及時性和準確性；</li>
            <li>本網站提供服務未受到幹擾；
            </li>
        </ul>
        <p>5.2 是否經由本網站下載或取得任何資料，由用戶自行考慮、衡量並且自負風險，因下載任何資料而導致用戶電腦系統的任何損壞或資料流失，用戶應負完全責任。希望用戶在使用本網站時，小心謹慎並運用常識；
        </p>
        <p>5.3 除本協議明確規定外，用戶經由本網站取得的其他建議和資訊，本網站不以任何明示或暗示方式對其提供保證。</p>
        <p>5.4 基於以下原因而造成的利潤、商譽、資料損失或其它無形損失，本網站不承擔任何直接、間接、附帶、特別、衍生性或懲罰性賠償（即使本網站已被告知前款賠償的可能性）</p>
        <ul>
            <li>本網站的使用或無法使用；</li>
            <li>用戶的傳輸或資料遭到未獲授權的存取或變更；</li>
            <li>本網站中任何第三方之聲明或行為；</li>
            <li>本網站在服務交易中為用戶提供交易機會，推薦交易方；</li>
            <li>本網站其它相關事宜。</li>
        </ul>
        <p>5.5 本網站只是為用戶提供知識產權檢索服務的平臺，對於用戶所發布的需求的合法性、真實性及其品質，以及用戶履行交易的能力等，本網站壹律不負任何擔保責任；</p>
        <p>5.6 本網站提供與其它互聯網上的網站或資源的鏈接，用戶可能會因此連結至其它運營商經營的網站，但不表示本網站與這些運營商有任何關系。其它運營商經營的網站均由各經營者自行負責，不屬於本網站控制及負責範圍之內。對於存在或來源於此類網站或資源的任何內容、廣告、物品或其它資料，本網站亦不予保證或負責。因使用或依賴任何此類網站或資源發布的或經由此類網站或資源獲得的任何內容、物品或服務所產生的任何損害或損失，本網站不負任何直接或間接的責任。
        </p>
        <h2>6. 本網站知識產權</h2>
        <p>6.1 本網站及本網站所使用的任何相關軟件、程序、內容，包括但不限於作品、圖片、檔案、資料、網站構架、網站版面的安排、網頁設計、經由本網站或廣告商向用戶呈現的廣告或資訊，均由本網站或其它權利人依法享有相應的知識產權，包括但不限於著作權、商標權、專利權或其它專屬權利等，受到相關法律的保護。未經本網站或權利人明示授權，用戶保證不修改、出租、出借、出售、散布本網站及本網站所使用的上述任何資料和資源，或根據上述資料和資源制作成任何種類產品；</p>
        <p>6.2 本網站授予用戶不可轉移及非專屬的使用權，使用戶可以通過單機計算機使用本網站的目標代碼（以下稱“軟件”），但用戶不得且不得允許任何第三方復制、修改、創作衍生作品、進行還原工程、反向組譯，或以其它方式破譯或試圖破譯源代碼，或出售、轉讓軟件或對軟件進行再授權，或以其它方式移轉“軟件”之任何權利。用戶同意不以任何方式修改軟件，或使用修改後的軟件</p>
        <p>6.3 用戶不得經由非本網站所提供的界面使用本網站。</p>
        <h2>7. 本網站用戶作品版權聲明</h2>
        <p>7.1 用戶在本網站所發布圖文作品，需享有相應版權。如涉及商業用途，應得到充分、合法、有效的許可。</p>
        <p>7.2 用戶在本網站上發布的內容，視作授權其作品使用在本網站平臺上，本網站平臺包括本網站以及本網站官方發布的適用於手持終端設備的應用或其他互聯網產品；</p>
        <p>7.3 如果任何第三方侵犯了本網站用戶相關的權利，用戶同意授權本網站或其指定的代理人代表本網站自身或用戶對該第三方提出警告、投訴、發起行政執法、訴訟、進行上訴，或談判和解，並且用戶同意在本網站認為必要的情況下參與共同維權；</p>
        <p>7.4 在未得到著作權人的授權時，也請不要將他人的作品全部或部分復制發表到本網站，如轉載站外信息到本網站請署名和註明出處；</p>
        <p>7.5 如個人或單位發現本網站上存在侵犯其自身合法權益的內容，請及時與本網站取得聯系，並提供具有法律效應的證明材料，以便本網站作出處理。本網站有權根據實際情況刪除相關的內容，並追究相關用戶的法律責任。給本網站或任何第三方造成損失的，侵權用戶應負全部責任；</p>
        <p>7.6 未經本網站許可，任何人不得盜鏈本網站下載資源；不得復制或仿造本站或者在非本網站所屬服務器上建立鏡像；</p>
        <p>7.7 本網站的標識（包括但不限於lanternfish.ai，APP，微信公眾號、訂閱號、服務號、微信小程序等）、源代碼、及所有頁面的版式設計等，版權歸本網站所有。未經授權，不得用於除本網站之外的任何站點；</p>
        <p>7.8 當本網站用戶的言論和行為侵犯了第三方的著作權或其他權利，責任在於用戶本人，本網站不承擔任何法律責任；</p>
        <p>7.9 本平臺用戶所發言論僅代表網友自己，不代表本平臺觀點。在本網站發表言論的網友，視為已經知道並理解這壹聲明。</p>
        <h2>8. 不可抗力</h2>
        <p>因不可抗力或者其他意外事件，使得本協議的履行不可能、不必要或者無意義的，雙方均不承擔責任。本合同所稱之不可抗力意指不能預見、不能避免並不能克服的客觀情況，包括但不限於戰爭、臺風、水災、火災、雷擊或地震、罷工、暴動、法定疾病、黑客攻擊、網絡病毒、電信部門技術管制、政府行為或任何其它自然或人為造成的災難等客觀情況。</p>
        <h2>9. 保密</h2>
        <p>
            用戶保證在使用本網站過程中所獲悉的屬於本網站及他方的且無法自公開渠道獲得的文件及資料（包括但不限於商業秘密、公司計劃、運營活動、財務信息、技術信息、經營信息及其他商業秘密）予以保密。未經該資料和文件的原提供方同意，用戶不得向第三方泄露該商業秘密的全部或者部分內容。但法律、法規、行政規章另有規定或者雙方另有約定的除外。
        </p>
        <h2>10. 糾紛解決</h2>
        <p>10.1 本協議受中國法律管轄，依照中華人民共和國法律進行解釋和執行。
            因本協議或本智能檢索平臺服務所引起的或與其有關的任何爭議，應向通過仲裁解決。仲裁地點為中國北京，仲裁機構為北京仲裁委員會（BAC），仲裁應當按照申請仲裁時該會現行有效的仲裁規則進行。仲裁語言使用中文。</p>
        <p>10.2 本網站有權受理並調處您與其他用戶因交易服務產生的糾紛，同時有權單方面獨立判斷其他用戶對您的舉報及索償是否成立，若判斷索償成立，則本網站有權劃撥您已支付的擔保金或交納的保證金以及賬戶余額進行相應償付。本網站沒有使用自用資金進行償付的義務，但若進行了該等支付，您應及時賠償本網站的全部損失，否則本網站有權通過前述方式抵減相應資金或權益，如仍無法彌補損失，則本網站保留繼續追償的權利。因本網站非司法機關，您完全理解並承認，本網站對證據的鑒別能力及對糾紛的處理能力有限，受理交易糾紛完全是基於您之委托，不保證處理結果符合您的期望，本網站有權決定是否參與爭議的調處；</p>
        <h2>11. 解釋權</h2>
        <p>
            本網站對本用戶協議包括基於本用戶協議制定的各項規則擁有最終解釋權。
        </p>
        <h2>12. 附則</h2>
        <p>在本協議中所使用的下列詞語，除非另有定義，應具有以下含義：</p>
        <ul>
            <li>“本網站”在無特別說明的情況下，均指藍燈魚智能檢索平臺網站（lanternfish.ai、lanternfish.hk、lanternfish.cn）。</li>
            <li>“用戶”：指具備完全民事權利能力和具備與所從事的民事行為相適應的行為能力的，對本網站平臺工具和服務進行使用的自然人、法人或其他組織。</li>
            <li>“需求方”：指在本網站註冊普通賬戶，在本網站上進行發布需求或購買增值服務的用戶。</li>
        </ul>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
</style>
