<template>
    <div>
        <zh-term v-if="$store.state.locale === 'cn'"></zh-term>
        <hk-term v-if="$store.state.locale === 'zh-hk'"></hk-term>
        <en-term v-if="$store.state.locale === 'en'"></en-term>
    </div>
</template>

<script>
import zhTerm from '@/components/legal/zh-cn/term'
import hkTerm from '@/components/legal/zh-hk/term'
import enTerm from '@/components/legal/en/term'
export default {
    components: {
        zhTerm,
        hkTerm,
        enTerm
    }
}
</script>

<style scoped>

</style>
