<template>
    <article>
        <h1>Lanternfish intelligent search platform User Agreement</h1>
        <p>Agreement
            The Lanternfish intelligent search platform (hereinafter referred to as “this Website”) provides services pursuant to the provisions of the Lanternfish intelligent search platform User Agreement (hereinafter referred to as “this Agreement”).This Agreement has the effect of a contract. Please be sure to read it carefully and fully understand all clauses, especially the terms of exemption or limitation of liability, law application, and dispute resolution clauses. The terms of the exemption or limitation of liability will be marked in bold and underlined, focus should be paid to those terms.
        </p>
        <p>
            By signing this Agreement, you have fully read and agree to be bound by all terms of this Agreement. This Website reserves the right to amend this Agreement from time to time and publish it on this Website. The amended terms shall become effective immediately upon their publication on this Website. In the event that you do not agree to any of the amended terms, you shall terminate this Agreement. Continuance of the use of the services after the publication of the amended terms shall be deemed as acceptance to be bound by the amended terms. This Agreement includes the text of the agreement and all rules that have been published on the Lanternfish intelligent search platform. And all relevant rules shall be an integral part of this Agreement and shall have the same force and effect as this Agreement
        </p>
        <h2>Article I User Qualification</h2>
        <p>1.1 The user should be natural person, legal person or other organization that has the full capacity for civil rights and capacity for civil conduct that compatible with their civil conducts. If you do not possess the aforementioned subject qualifications, please do not use the services. Otherwise you and your guardian shall bear all consequences, and this Website has the right to cancel/permanently suspended your account. In addition, this Website reserves the right to claim against you and your guardian. If you register on this Website on behalf of a company or other legal entity, you declare and guarantee that you have the power to bind the company or the subject to this agreement.</p>
        <p>1.2 The user shall provide their real name (entity name) and contact number when registering.</p>
        <p>1.3 The user promises to abide by all laws and regulations, the principles of the socialist system, national interest, legitimate rights and interest of citizens, public order, social morality, and information authenticity.</p>
        <h2>Article II Rights and Obligations of the User</h2>
        <p>2.1 The user has right to use this Website to publish demand information, query information of other users, publish works of information, participate in demand consultation and participate in providing services in accordance with this Agreement and the relevant rules which are published on this Website. And the user also has right to post related product information, participate in related activities, and enjoy other relevant information and services provided on this Website</p>
        <p>2.2 The user is required to keep their own user account and password and is required to take full responsibility for all activities under their account, including but not limited to, publishing demand information, agreeing to various agreements, rules, and participating in demand bidding. The user has right to change their login password according to their needs. Any loss caused by the user's fault, including but not limited to, not following operation tips, not carrying out operations in time, forgetting and/or divulging their password, their password being cracked by others, and the user’s computer being invaded by others, shall be borne by the user.</p>
        <p>2.3 The user shall provide true and accurate information for registration, including but not limited to their real name (company name), identity document information, contact number, address, and postcode. The user guarantees that this Website can contact you based on the above information. In addition, the user shall update their registration information in time when the relevant information is changed. This Website will not take any responsibility for any loss caused by false, fake, outdated or incomplete information.</p>
        <p>2.4 The user’s registered account name, head portraits, and introduction shall not contain any contents which: </p>
        <ul>
            <li>Violates the constitution or laws and regulations；</li>
            <li>Jeopardizes national security, leaking state secrets, inciting subversion of state power, and destroying national unity;</li>
            <li>Harms national honor and interests, and harming public interests;</li>
            <li>Incites ethnic hatred and ethnic discrimination, and undermines ethnic unity;</li>
            <li>Undermines the state's religious policies, and promotes cult and feudal superstition;</li>
            <li>Spreads rumors, disturbs social order, or undermines social stability;</li>
            <li>ncludes pornographic, gambling, violent, homicidal or terrorist information, or instigating others to commit any crime;</li>
            <li>Insults or defames any other person, or infringes upon the lawful rights and interests of others;</li>
            <li> Contains other contents prohibited by laws and administrative regulations.</li>
        </ul>
        <p>2.5 The user shall not register accounts with false information.</p>
        <p>2.6 The user shall not use the names of their affiliates or social celebrities to register accounts. If any responsibility or loss is caused by any person who is authorized by the user, the user shall take the responsibility and loss. Furthermore, the user shall take joint responsibility with the actual user, if above behavior brings loss to this Website.</p>
        <p>2.7 The user shall not, in any form, transfer or authorize others to use their account on this Website.</p>
        <p>2.8 The user is obligated to ensure that the requirement information, which is published on this Website, is true, accurate and non-misleading.</p>
        <p>2.9 All requirements and service information which are published on this Website shall not violate any laws, regulations, administrative regulations, public interests, public morals, and regulation of this Website shall not infringe on others’ intellectual property and others’ legitimate rights and interests.</p>
        <p>2.10 The user shall abide by the principle of good faith in the course of transactions on this Website, shall not intervene or manipulate information release, shall not disrupt online trading order or take other unfair competition means, and shall not engage in improper actions which are unrelated to online transactions.</p>
        <p>2.11 The user shall not take any improper measures, including but not limited to false orders and exchange praise, to improve anyone’s creditworthiness, to give malicious comments to other users, or to reduce other user’s credit rating.</p>
        <p>2.12 The user shall not violate Measures for the Administration of Bank Card Business in using bank cards or using credit cards for cash.</p>
        <p>2.13 The user guarantee that all actions on this Website comply with laws, regulations, administrative rules, all terms on this Website, and public interest or public morality. In the event of any legal consequence arising from the breach of the above provisions, the user shall bear the corresponding legal liability in their own name.</p>
        <p>2.14 The user can request this Website to coordinate processing if they have any dispute with other users during transactions. If the user find other users have undertaken any illegal actions or any behaviors which violate this Agreement, the user can report them to this Website.</p>
        <p>2.15 Except as stated otherwise by terms in this Agreement, the user shall bear the relevant expenses incurred by the transaction and pay taxes according to law.</p>
        <p>2.16 Without the written permission of this Website, the user shall not use any information on this Website or any information which is published on the trading platform for other commercial use, including but not limited to producing, distributing, or publishing derivative works by copying, modifying and/or translating.</p>
        <p>2.17 The user is not allowed to login or destroy this Website’s services through the following methods:</p>
        <ul>
            <li>Visiting or logging in by any robot software, spider software, refresh software, or other automated methods.</li>
            <li>Creating or possibly causing an unreasonable or disproportionate load on the site's structure.</li>
            <li>Interfering or attempting to interfere with the normal work of this Website or any activity on this Website.</li>
        </ul>
        <p>2.18 The user agrees to receive information from this Website, including but limited to event information, transaction information, and promotional information.</p>
        <p>2.19 The user understands and agrees that if the user registers and completes the certification successfully on this Website. All operations such as withdrawals in transactions on this site must be made through the relevant payment accounts.</p>
        <p>2.20 The user understands and agrees with the intelligent search platform User Agreement and expressly authorizes the intelligent search platform to communicate and send information using the email address provided during the user's registration, including but not limited to reminder emails, promotional emails, etc.</p>
        <h2>Article III Rights and Obligations of This Website</h2>
        <p>3.1 This Website shall provide users with qualified intellectual property legal services according to their requirement and payment.</p>
        <p>3.2 This Website is obligated to ensure the normal operation of the online platform at the existing performance level. This Website shall make reasonable efforts to avoid service disruption or limit the interruption to the shortest time and shall ensure to provide a smooth process of online communication activities.</p>
        <p>3.3 This website is obligated to respond promptly to any questions or problems which users are facing when they use this Website concerning registration or transaction problems and situations.</p>
        <p>3.4 This Website has the right to review the user’s registration information. If there are any questions or doubts about registration information, this Website can issue notices to inquire users and request explanations or corrections to their information.</p>
        <p>3.5 If user has a dispute with another user due to online transactions on this Website, and the user reports to this Website or this Website acknowledges the dispute on its own, this Website has right to contact the involved parties through e-mail or telephone to inquire about detailed information and feedback all information. If user requires this Website to provide relevant information through judicial authorities in accordance with legal procedures, this Website will actively cooperate and provide the relevant information.</p>
        <p>3.6 Due to the special nature of the internet information platform, this Website is not obliged to perform a pre-approval of the user's trading behavior and other related matters. However, this Website reserves the right to restrict the user’s activities without agreement, verify relevant information, issue a warning notice, or suspend and refuse to provide services to the user if the following circumstances occur:</p>
        <ul>
            <li>If the user conducts certification in the name of a non-natural person, and that entity is canceled by itself, or revoked or withdrawn by an authority;</li>
            <li>The user violates this Agreement, other relevant terms, or amended terms;</li>
            <li>Other users or third parties notify this Website that users or transactions exhibit illegal or inappropriate behaviors, and they provide relevant evidence, and this Website cannot contact the user or cannot verify the user’s information.</li>
            <li>Other users or third parties notify this Website that users or transactions exhibit illegal or inappropriate behaviors, and they provide relevant evidence, and based on this Website’s ordinary non-professionals judgment, we believe that those behaviors will harm lawful rights and interests of this Website or other third parties.</li>
        </ul>
        <p>3.7 If this Website can confirm that a user exhibits any illegal behavior, has constituted any breach of this Agreement, or other misconduct according to the national laws, regulations, administrative regulations, this Agreement, and all facts, this Website reserves the right to publish the user’s misconduct on the online platform without the user’s permission, and has right to delete the relevant information of the user and terminate service at any time.</p>
        <p>3.8 According to this Agreement and related terms, this Website has the right to freeze, use, pay in advance, refund, and dispose users' funds in their account on this Website. If the funds in the account that have been blocked due to a violation have remaining balance after the blocking, the user may apply for withdrawal of said funds.</p>
        <p>3.9 This Website reserves the right to delete or take other restrictive measures to deal with the following information without notice: for the purpose of speculation; malicious fraud or false content; unrelated online transactions or not for trading purposes; malicious bidding or other attempts to disrupt the normal trading order; violation of public interest or potential serious damage to the legitimate interests of this Website and other users</p>
        <p>3.10 This Website shall warrant keeping all user information confidential and not to disclose it to any third party or authorize any third party to use it, except when:</p>
        <ul>
            <li>It can be provided in accordance with the clauses hereof or the provisions of other user agreements, contracts concluded and reached between you and this Website;</li>
            <li>It must be provided in accordance with the provisions of laws and regulations;</li>
            <li>It is required to be provided by administrative, judicial or other authorities;</li>
            <li>The user agrees to their information being provided to third parties;</li>
            <li>It is submitted to solve a report incident or legal proceeding;</li>
            <li> It is necessary for this Website to take reasonable actions to prevent serious illegal activity or suspicious crimes;</li>
            <li>It is provided to third parties in order to provide to you products, services and information, including the situation where this Website provides to you products, services and consultation.</li>
        </ul>
        <p>3.11 This Website and all the websites of its affiliated companies shall have the right to use your information.</p>
        <h2>Article IV Discontinuation and Termination of Service</h2>
        <p>4.1 This Website may, at its sole discretion, terminate the service to users for any reason (including but not limited this Website believing that the user has violated this Agreement and related rules, or that the user has not logged in to this Website within 6 months, etc.), but it will save the user's information in entirety on this for a maximum term of two years (including but not limited to user information, product information, transaction information, etc.). Meanwhile, this Website may, at its sole discretion, cease to provide all or part of the services at any time, with or without notice. After the termination of service, this Website is not obligated to retain any information in or related to the original account of the user, or to forward any information that has not been read or sent to the user or a third party.</p>
        <p>4.2 If the user requests to terminate the service of this Website, it must be reviewed and approved by this Website before the user can terminate the agreement with this Website, but this Website still retains the following rights:</p>
        <ul>
            <li>This Website is entitled to keep the user's data within the time specified by laws, regulations and rules, including but not limited to the previous user information, transaction records, etc.;</li>
            <li>If the user has committed an illegal act or violates this Agreement on the trading platform of this website before the termination of service, this Website may still exercise the rights provided in this Agreement.</li>
        </ul>
        <p>4.3 This Website may terminate the provision of services to the user if the following conditions exist:</p>
        <ul>
            <li>When the user violates the provisions of this Agreement and related rules, this Website has the right to terminate the service provided to the user. The site will notify the user when the service is terminated. If, after the service has been terminated this Website, the user is registered as a new user of this website directly or indirectly or in the name of another person once again, this Website has the right to unilaterally terminate the service for this user again;</li>
            <li>This Website finds that the main content of the user registration data is false, this Website has the right to terminate the service for the user at any time;</li>
            <li>When the agreement is terminated or updated, the user has not confirmed the new agreement;</li>
            <li>Other situations in which this Website believes that the service needs to be terminated.</li>
        </ul>
        <h2>Article V Scope of Responsibilities</h2>
        <p>5.1 This Website cannot foresee any technical problems or other difficulties at any time. Such problems or difficulties may result in data loss or other service interruptions. The service of this Website is based on existing technology. This website does not guarantee the following items:</p>
        <ul>
            <li>That this Website will meet the requirements of all users;</li>
            <li>That this Website provides a timely and accurate service;</li>
            <li>That this Website provides an uninterrupted service.</li>
        </ul>
        <p>5.2 Whether to download or obtain any information via this Website or use of any other functions and services on this Website, is determined by the user’s own consideration and measures and such action and use which will be solely at the user’s own risk. The user shall be fully responsible for any damage to the user’s computer system or data loss resulting from downloading any data.</p>
        <p>5.3 Unless expressly provided in this Agreement, this Website does not provide any warranty in any express or implied manner to the other suggestions or information the user obtained via this site.</p>
        <p>5.4 This Website shall not be liable for any direct, indirect, incidental, special, derivative, or punitive damages (even if this Website has been informed of the possibility of compensation in the preceding paragraph) based on any profit, goodwill, data loss or other intangible loss resulting from the following reasons:</p>
        <ul>
            <li>Use of or inability to use this Website;</li>
            <li>Unauthorized access or change of the user's transmission or data;</li>
            <li>A statement or behavior of any third party on this Website;</li>
            <li>This Website provides users with trading opportunities and recommends the transaction party in the service transaction;</li>
            <li>Other related affairs of this website.</li>
        </ul>
        <p>5.5 This Website is a platform that provides intellectual property service for users. This Website will not bear any warranties or responsibility for the legitimacy, authenticity and quality of the requirements issued by users, and the ability of users to perform transactions.</p>
        <p>5.6 This Website provides links to other websites or resources on the Internet, and the users may be linked to the other operators’ websites, however, it is not implied that this Website has any relationship with these operators. The websites operated by the other operators are the responsibility of their own operators and are not within the control and responsibility of this Website. This Website does not guarantee or take responsibility for any content, advertisements, articles or other materials that exist or originate from such websites or resources. This Website shall not bear any direct or indirect responsibility for damage or loss resulting from the use or reliance on any content, goods or services published on or via such websites or resources.
        </p>
        <h2>Article VI Intellectual Property of this Website</h2>
        <p>6.1 This Website or the other right owners shall legally own the intellectual property, including but not limited to copyright, trademark right, patent right and other exclusive rights, etc. of all software, programs and the content on this Website, including but not limited to works, pictures, files, data, website structure, framework and website design, information or advertisement presented to users via this Website or advertisers. Unless agreed to by this Website or the other right owners thereof, no one shall modify, rent, lend, sell, or distribute any of the above data and resources in this Website or used on this Website, or make any kind of product based on the above data and resources.</p>
        <p>6.2 This Website authorizes the users a non-transferable and non-exclusive right to use, so that the users can use this Website's target code (hereinafter referred to as the “Software”) through a stand-alone computer, however, the users shall not by themselves or through a third party copy, modify, create derived works, reverse engineer, disassemble, or decipher or attempt to decipher the source code through other method, or sell, transfer or re-authorize or employ any other method to transfer the Software’s right. The user agrees not to modify the Software in any way or use the modified Software.</p>
        <p>6.3 The user may not use this Website via an interface provided by other websites.</p>
        <h2>Article VII Copyright Statement</h2>
        <p>7.1 The graphic works published by users on this Website enjoy the corresponding copyright. If it involves commercial use, the above works shall be fully, legally and effectively licensed.</p>
        <p>7.2 The works posted by the user on this Website are deemed as authorizing this Website and the hand-held terminal applications or other internet products officially published by this Website to use the above works;</p>
        <p>7.3 If any third party infringes the user’s rights of this Website, the user agrees to authorize this Website or its designated agent to present a warning, complaint, initiate administrative enforcement, litigation, appeal, or negotiate on behalf of this Website itself or the user, and the user agrees to participate in rights protection if this Website deems it is necessary.</p>
        <p>7.4 Unless authorized by the copyright owner, it is forbidden to copy the copyright owner’s part or whole works published on this Website. Any transportation of information that has been published by other carriers must be signed for and the source must be indicated.</p>
        <p>7.5 If an individual or entity finds content on this Website that infringes their own legitimate rights and interests, they must contact this Website in a timely manner and provide evidence with legal effects for this Website’s execution. This Website reserves the right to delete relevant content according to the actual situation and to investigate the legal liability of the relevant user. The users shall bear all responsibilities for any loss of this Website or third party caused by them;</p>
        <p>7.6 Without the permission of this Website, users must not quote the download link of this Website to other websites; nor may they copy or imitate this site or create images on servers not owned by the site or create a mirror on a server that is not owned by this Website.</p>
        <p>7.7 The copyright of the mark (including but not limited to lanternfish.ai, the application, the WeChat official account, and subscribed accounts), source code, and layout design of all pages of this Website are owned by this Website;</p>
        <p>7.8 When the commentaries and behaviors of users of this Website infringe the copyrights or other rights of third parties, this Website does not bear any legal responsibilities;</p>
        <p>7.9 The user’s commentaries on this platform only represent the users themselves and do not represent the views of this Website and its operators. This Website considers that the users who have expressed themselves on this Website are aware of and understand the above statement.</p>
        <h2>Article VIII Force Majeure</h2>
        <p>Neither party shall be liable to the other for delays or failure to perform which is due to force majeure or other accidents. For the purpose of this Agreement, force majeure shall refer to objective situations that are unforeseeable, unavoidable and insurmountable, including but not limited to, wars, typhoons, flooding, fire, lightning, earthquakes, strikes, riots, legal diseases, hacker attacks, network viruses, telecommunication sector technical controls, government actions, or any other natural or man-made disaster.</p>
        <h2>Article IX Confidentiality</h2>
        <p>The user warrant to keep secret the documents and information (including but not limited to, the business secrets, the company projects, the operation activities, the financial information, the technology information, the management information and other business secrets) they have obtained from this Website and other parties during the use of this website and cannot be obtained from the public channels. Without the consent of the original provider of the information and documents, the users shall not disclose all or part of the above business secrets to the third party.</p>
        <h2>Article X Dispute Resolution</h2>
        <p>10.1 This Agreement is governed by the laws of the People’s Republic of China, and is interpreted and enforced in accordance with the laws of the People’s Republic of China. This Agreement or any dispute arising from or related to this Website shall be settled through arbitration. The place of arbitration is Beijing, China, and the arbitration institution is the Beijing Arbitration Commission (BAC). The arbitration shall be conducted in accordance with the current effective arbitration rules of the association when applying for arbitration. The arbitration language shall be Chinese</p>
        <p>10.2 This Website has the right to receive and mediate disputes arising from transaction services between users. At the same time, the Website has the right to unilaterally and independently determine whether users’ reports and claims against other users are established. If it is established, this Website has the right to transfer the security paid by users or the deposit paid and the balance of the account for the corresponding reimbursement. This Website does not have the obligation to use its own funds for compensation, but if such payment is made, the user shall compensate for all losses on this Website in time. Otherwise, this Website has the right to deduct the corresponding funds or equity through the aforementioned method. If the losses cannot be recovered in this manner, this Website reserves the right to claim compensation from users. Since this Website is not a judiciary authority, by accepting this Agreement, users fully understand and accept that this Website's functions of examining evidence and resolving with disputes are limited. Acceptance of transaction disputes is entirely based on users’ entrustment and does not warrant that the processing results meet users’ expectations. This Website has the right to decide whether to participate in the mediation of disputes.</p>
        <h2>Article XI Interpreting</h2>
        <p>This Website reserves right of final interpretation of this Agreement, including rules based on this Agreement.</p>
        <h2>Article XII Supplementary Provisions</h2>
        <p>This Website reserves right of final interpretation of this Agreement, including rules based on this Agreement.</p>
        <ul>
            <li>Unless otherwise specified, "this Website" refers to the Lanternfish intelligent search platform website (lanternfish.ai, lanternfish.cn, lanternfish.hk).</li>
            <li>“The user” or “the users” refers to natural persons, legal persons or other organizations that have full civil rights capabilities and have the ability to act in accordance with the civil acts they engage in.</li>
            <li>“The demander” refers to a user who has registered as an ordinary account and publishes a demand or purchases value-added services on this Website.</li>
        </ul>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
</style>
