<template>
    <article>
        <h1>蓝灯鱼用户协议</h1>
        <p>蓝灯鱼（以下简称“本网站”）依据《蓝灯鱼用户协议》（以下简称“本协议”）的规定提供服务，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。 
            若您已经注册为本网站用户，即表示您已充分阅读且自愿接受本协议的条款约束。本网站有权随时变更本协议并在本网站上予以公告。经修订的条款一经在本网站的公布后，立即自动生效。如您不同意相关变更，有权停止使用本网站。如您继续使用本网站，则视为接受本网站对本协议做出的修改。本协议内容包括协议正文及所有蓝灯鱼智能检索平台已经发布的各类规则。相关规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。
        </p>
        <h2>1. 用户资格</h2>
        <p>1.1 用户应为具备完全民事权利能力且民事行为能力与所从事的民事行为相适应的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且本网站有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体在本网站登记，则您声明和保证，您有权使该公司或该主体受本协议的约束。</p>
        <p>1.2 用户在注册时需提供真实姓名（名称）及联系电话。</p>
        <p>1.3 本网站用户须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性。</p>
        <h2>2. 用户的权利和义务</h2>
        <p>2.1 用户有权根据本协议及本网站发布的相关规则，利用本网站发布需求信息、查询用户信息、发布作品信息、参与需求咨询、参与提供服务，在本网站发布相关产品信息，参加本网站的有关活动及有权享受本网站提供的其他有关资讯及信息服务；</p>
        <p>2.2 用户须自行保管自己的用户账号和密码，且须对在用户账号密码下发生的所有活动（包括但不限于发布需求信息、网上点击同意各类协议、规则、参与需求投标或等）承担责任。用户有权根据需要更改登录和账户密码。因用户的过错导致的任何损失由用户自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，用户使用的计算机被他人侵入。</p>
        <p>2.3 用户应当向本网站提供真实准确的注册信息，包括但不限于真实姓名、身份证认证、联系电话、地址、邮政编码等。保证本网站可以通过上述联系方式与自己进行联系。同时，用户也应当在相关资料实际变更时及时更新有关注册资料；若因用户提供错误、虚假、过时或不完整的资料而导致的任何损失，本网站不承担任何责任。</p>
        <p>2.4 用户在本网站注册的账号名称、头像及简介，不得有下列情形：</p>
        <ul>
            <li>违反宪法或法律法规的；</li>
            <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </li>
            <li>损害国家荣誉和利益的，损害公共利益的；
            </li>
            <li>煽动民族仇恨、民族歧视，破坏民族团结的；
            </li>
            <li>破坏国家宗教政策，宣扬邪教和封建迷信的；
            </li>
            <li>散布谣言，扰乱社会秩序，破坏社会稳定的；
            </li>
            <li>
                散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </li>
            <li>侮辱或者诽谤他人，侵范他人合法权益的；
            </li>
            <li>含有法律、行政法规禁止的其他内容的。
            </li>
        </ul>
        <p>2.5 用户不得以虚假信息注册账号；</p>
        <p>2.6 用户不得冒用其关联机构或社会名人的名称注册账号。如因用户授权的被委托人故意或者过失导致的账户冒用而引发的责任和相关损失，由委托人承担。因此给本站带来的损害，委托人应与实际使用人承担连带责任。</p>
        <p>2.7 用户不得以任何形式擅自转让或授权他人使用自己在本网站的帐号；
        </p>
        <p>2.8 用户有义务确保在本网站上发布的需求信息真实、准确，无误导性；</p>
        <p>2.9 用户在本网站上发布的需求或服务信息，不得违反国家法律、法规、行政规章的规定、不得违背社会公共利益或公共道德、不得侵犯他人知识产权或其他合法权益的信息、不得违反本网站的相关规定；
        </p>
        <p>2.10 用户在本网站交易中应当遵守诚实信用原则，不得干预或操纵信息发布，不得扰乱网上交易秩序或采取其他不正当竞争手段，不得从事与网上交易无关的不当行为；</p>
        <p>2.11 用户不应采取不正当手段（包括但不限于虚假订单、互换好评等方式）提高自身或他人信用度，恶意差评其他用户，或降低其他用户信用度；
        </p>
        <p>2.12 用户不得违反《银行卡业务管理办法》使用银行卡，或利用信用卡套取现金；</p>
        <p>2.13 用户承诺自己在使用本网站实施的所有行为遵守法律、法规、行政规章和本网站的相关规定以及各种社会公共利益或公共道德。如违反上述规定导致任何法律后果，用户将以自己的名义独立承担相应的法律责任；
        </p>
        <p>2.14 用户在本网站网上交易过程中如与其他用户因交易产生纠纷，可以请求本网站从中予以协调处理。用户如发现其他用户有违法或违反本协议的行为，可以向本网站举报；
        </p>
        <p>2.15 除本网站另有规定外，用户应当自行承担因交易产生的相关费用，并依法纳税；
        </p>
        <p>2.16 未经本网站书面允许，用户不得将本网站的任何资料以及在交易平台上所展示的任何信息于其他商业性用途（包括但不限于以复制、修改、翻译等形式制作、分发或公开展示衍生作品）；
        </p>
        <p>2.17 用户不得使用以下方式登录网站或破坏网站所提供的服务：</p>
        <ul>
            <li>以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录本网站；
            </li>
            <li>对本网站部结构造成或可能造成不合理或不合比例的重大负荷的行为；
            </li>
            <li>
                干扰或试图干扰网站的正常工作或网站上进行的任何活动。
            </li>
        </ul>
        <p>2.18 用户同意接收来自本网站的信息，包括但不限于活动信息、交易信息、促销信息等。
        </p>
        <p>2.19 用户了解并同意，如用户在本网站成功注册，并成功完成实名认证，在本网站交易中提现等操作都需要通过相关支付账户进行。
        </p>
        <p>2.20 用户了解并同意此智能检索平台协议，明确并允许智能检索平台及蓝灯鱼向其注册智能检索平台的邮箱发送信息，包括但不限于提醒邮件、推广邮件等。
        </p>
        <h2>3. 本网站的权利和义务</h2>
        <p>
            3.1 本网站应根据您选择的服务以及交纳款项的情况向您提供合格的智能检索服务；
        </p>
        <p>
            3.2 本网站有义务在现有技术水平的基础上努力确保整个网上交流平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行；
        </p>
        <p>3.3 本网站有义务对用户使用本网站时遇到的有关注册或交易问题及反映的情况做出及时的回复；</p>
        <p>3.4 本网站有权对用户的注册资料进行审查，对存在任何问题或怀疑的注册资料，本网站有权发出通知询问用户并要求用户做出解释、改正；</p>
        <p>3.5 用户因在本网站网上交易与其他用户产生纠纷的，用户将纠纷告知本网站，或本网站知悉纠纷情况的，本网站有权通过电子邮件及电话联系向纠纷双方了解纠纷情况，并将所了解的情况通过电子邮件互相通知对方；如用户通过司法机关依照法定程序要求本网站提供相关资料，本网站将积极配合并提供有关资料；
        </p>
        <p>3.6 因互联网信息平台的特殊性，本网站没有义务对用户的交易行为以及与交易有关的其他事项进行事先审查，但如发生以下情形，本网站有权无需征得用户的同意而限制用户的活动、向用户核实有关资料、发出警告通知或中止及拒绝向该用户提供服务：
        </p>
        <ul>
            <li>用户以非自然人名义进行认证之后,认证主体自行注销或者经有权机关吊销或撤销的；
            </li>
            <li>用户违反本协议相关规则或修行后条款及规则的；
            </li>
            <li>用户或其他第三方通知本网站，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据，而本网站无法联系到该用户或无法验证该用户向本网站提供的任何资料；
            </li>
            <li>用户或其他第三方通知本网站，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据。本网站以普通非专业人员的知识水平标准对相关内容进行判别，认为该内容或行为会导致第三方或本网站的合法权益受损；</li>
        </ul>
        <p>3.7 根据国家法律、法规、行政规章规定、本协议的内容和本网站所掌握的事实依据，可以认定该用户存在违法或违反本协议行为以及在本网站交易平台上的其他不当行为，本网站有权无需征得用户的同意在本网站交易平台及所在网站上以网络发布形式公布该用户的不当行为，并有权随时删除相关信息、终止服务提供；
        </p>
        <p>3.8 本网站依据本协议及相关规则，可以冻结、使用、先行赔付、退款并处置用户在本网站账户内的资金。因违规而被封号的用户账户中的资金在按照规定进行处置后尚有余额的，该用户可申请提现；
        </p>
        <p>3.9 本网站有权在不通知用户的前提下，删除或采取其他限制性措施处理下列信息：包括但不限于以规避费用为目的；以炒作为目的；存在恶意欺诈或内容虚假；与网上交易无关或不是以交易为目的；存在恶意竞价或其他试图扰乱正常交易秩序因素；违反公共利益或可能严重损害本网站和其他用户合法利益；
        </p>
        <p>3.10 本网站承诺对您资料采取对外保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：
        </p>
        <ul>
            <li>依据本协议条款或者您与本网站之间其他服务协议、合同等规定可以提供；
            </li>
            <li>依据法律法规的规定应当提供；</li>
            <li>行政、司法等职权部门要求本网站提供；</li>
            <li>您同意本网站向第三方提供；</li>
            <li>本网站解决举报事件、提起诉讼而提交的；</li>
            <li>本网站为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；</li>
            <li>本网站为向您提供产品、服务、信息而向第三方提供的，包括本网站通过第三方向您提供产品、服务、咨询。</li>
        </ul>
        <p>3.11 本网站及本网站关联公司所有网站有权使用您的资料和信息作为推广宣传之用，但不会对外泄露账号内容。
        </p>
        <h2>4. 服务的中止和终止</h2>
        <p>4.1 本网站可自行全权决定以任何理由（包括但不限于本网站认为用户已违反本协议及相关规则，或用户在超过6个月内未登录本网站等）终止对用户的服务，但会在两年内保存用户在本网站的全部资料（包括但不限于用户信息、产品信息、交易信息等）。同时本网站可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供全部或部分服务。服务终止后，本网站没有义务为用户保留原账户中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方；
        </p>
        <p>4.2 若用户申请终止本网站服务，需经本网站审核同意，方可解除与本网站的协议关系，但本网站仍保留下列权利：
        </p>
        <ul>
            <li>本网站有权在法律、法规、行政规章规定的时间内保留该用户的资料,包括但不限于以前的用户资料、交易记录等;
            </li>
            <li>
                若终止服务之前，该用户在本网站交易平台上存在违法行为或违反本协议的行为，本网站仍可行使本协议所规定的权利。
            </li>
        </ul>
        <p>4.3 用户存在下列情况，本网站可以终止向该用户提供服务：</p>
        <ul>
            <li>在用户违反本协议及相关规则规定时，本网站有权终止向该用户提供服务。本网站将在中断服务时通知用户。但该用户在被本网站终止提供服务后，再一次直接或间接或以他人名义注册为本网站用户的，本网站有权再次单方面终止为该用户提供服务；</li>
            <li>本网站发现用户注册资料中主要内容是虚假的，本网站有权随时终止为该用户提供服务；
            </li>
            <li>本协议终止或更新时，用户未确认新的协议的；
            </li>
            <li>其它本网站认为需终止服务的情况。
            </li>
        </ul>
        <h2>5. 本网站的责任范围</h2>
        <p>5.1 本网站不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。本网站是在现有技术基础上提供的服务。本网站不保证以下事项∶
        </p>
        <ul>
            <li>本网站将符合所有用户的要求；
            </li>
            <li>本网站提供服务的及时性和准确性；</li>
            <li>本网站提供服务未受到干扰；
            </li>
        </ul>
        <p>5.2 是否经由本网站下载或取得任何资料，由用户自行考虑、衡量并且自负风险，因下载任何资料而导致用户电脑系统的任何损坏或资料流失，用户应负完全责任。希望用户在使用本网站时，小心谨慎并运用常识；
        </p>
        <p>5.3 除本协议明确规定外，用户经由本网站取得的其他建议和资讯，本网站不以任何明示或暗示方式对其提供保证。</p>
        <p>5.4 基于以下原因而造成的利润、商誉、资料损失或其它无形损失，本网站不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使本网站已被告知前款赔偿的可能性）</p>
        <ul>
            <li>本网站的使用或无法使用；</li>
            <li>用户的传输或资料遭到未获授权的存取或变更；</li>
            <li>本网站中任何第三方之声明或行为；</li>
            <li>本网站在服务交易中为用户提供交易机会，推荐交易方；</li>
            <li>本网站其它相关事宜。</li>
        </ul>
        <p>5.5 本网站只是为用户提供知识产权检索服务的平台，对于用户所发布的需求的合法性、真实性及其品质，以及用户履行交易的能力等，本网站一律不负任何担保责任；</p>
        <p>5.6 本网站提供与其它互联网上的网站或资源的链接，用户可能会因此连结至其它运营商经营的网站，但不表示本网站与这些运营商有任何关系。其它运营商经营的网站均由各经营者自行负责，不属于本网站控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，本网站亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，本网站不负任何直接或间接的责任。
        </p>
        <h2>6. 本网站知识产权</h2>
        <p>6.1 本网站及本网站所使用的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、网站构架、网站版面的安排、网页设计、经由本网站或广告商向用户呈现的广告或资讯，均由本网站或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经本网站或权利人明示授权，用户保证不修改、出租、出借、出售、散布本网站及本网站所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品；</p>
        <p>6.2 本网站授予用户不可转移及非专属的使用权，使用户可以通过单机计算机使用本网站的目标代码（以下称“软件”），但用户不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让软件或对软件进行再授权，或以其它方式移转“软件”之任何权利。用户同意不以任何方式修改软件，或使用修改后的软件</p>
        <p>6.3 用户不得经由非本网站所提供的界面使用本网站。</p>
        <h2>7. 本网站用户作品版权声明</h2>
        <p>7.1 用户在本网站所发布图文作品，需享有相应版权。如涉及商业用途，应得到充分、合法、有效的许可。</p>
        <p>7.2 用户在本网站上发布的内容，视作授权其作品使用在本网站平台上，本网站平台包括本网站以及本网站官方发布的适用于手持终端设备的应用或其他互联网产品；</p>
        <p>7.3 如果任何第三方侵犯了本网站用户相关的权利，用户同意授权本网站或其指定的代理人代表本网站自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在本网站认为必要的情况下参与共同维权；</p>
        <p>7.4 在未得到著作权人的授权时，也请不要将他人的作品全部或部分复制发表到本网站，如转载站外信息到本网站请署名和注明出处；</p>
        <p>7.5 如个人或单位发现本网站上存在侵犯其自身合法权益的内容，请及时与本网站取得联系，并提供具有法律效应的证明材料，以便本网站作出处理。本网站有权根据实际情况删除相关的内容，并追究相关用户的法律责任。给本网站或任何第三方造成损失的，侵权用户应负全部责任；</p>
        <p>7.6 未经本网站许可，任何人不得盗链本网站下载资源；不得复制或仿造本站或者在非本网站所属服务器上建立镜像；</p>
        <p>7.7 本网站的标识（包括但不限于lanternfish.ai，APP，微信公众号、订阅号、服务号、微信小程序等）、源代码、及所有页面的版式设计等，版权归本网站所有。未经授权，不得用于除本网站之外的任何站点；</p>
        <p>7.8 当本网站用户的言论和行为侵犯了第三方的著作权或其他权利，责任在于用户本人，本网站不承担任何法律责任；</p>
        <p>7.9 本平台用户所发言论仅代表网友自己，不代表本平台观点。在本网站发表言论的网友，视为已经知道并理解这一声明。</p>
        <h2>8. 不可抗力</h2>
        <p>因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本合同所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。</p>
        <h2>9. 保密</h2>
        <p>
            用户保证在使用本网站过程中所获悉的属于本网站及他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，用户不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。
        </p>
        <h2>10. 纠纷解决</h2>
        <p>10.1 本协议受中国法律管辖，依照中华人民共和国法律进行解释和执行。
            因本协议或本智能检索平台服务所引起的或与其有关的任何争议，应向通过仲裁解决。仲裁地点为中国北京，仲裁机构为北京仲裁委员会（BAC），仲裁应当按照申请仲裁时该会现行有效的仲裁规则进行。仲裁语言使用中文。</p>
        <p>10.2 本网站有权受理并调处您与其他用户因交易服务产生的纠纷，同时有权单方面独立判断其他用户对您的举报及索偿是否成立，若判断索偿成立，则本网站有权划拨您已支付的担保金或交纳的保证金以及账户余额进行相应偿付。本网站没有使用自用资金进行偿付的义务，但若进行了该等支付，您应及时赔偿本网站的全部损失，否则本网站有权通过前述方式抵减相应资金或权益，如仍无法弥补损失，则本网站保留继续追偿的权利。因本网站非司法机关，您完全理解并承认，本网站对证据的鉴别能力及对纠纷的处理能力有限，受理交易纠纷完全是基于您之委托，不保证处理结果符合您的期望，本网站有权决定是否参与争议的调处；</p>
        <h2>11. 解释权</h2>
        <p>
            本网站对本用户协议包括基于本用户协议制定的各项规则拥有最终解释权。
        </p>
        <h2>12. 附则</h2>
        <p>在本协议中所使用的下列词语，除非另有定义，应具有以下含义：</p>
        <ul>
            <li>“本网站”在无特别说明的情况下，均指蓝灯鱼智能检索平台网站（lanternfish.ai、lanternfish.hk、lanternfish.cn）。</li>
            <li>“用户”：指具备完全民事权利能力和具备与所从事的民事行为相适应的行为能力的，对本网站平台工具和服务进行使用的自然人、法人或其他组织。</li>
            <li>“需求方”：指在本网站注册普通账户，在本网站上进行发布需求或购买增值服务的用户。</li>
        </ul>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
article {
	box-sizing: border-box;
	h1 {
		text-align: center;
		font-weight: 400;
		color: #022144;
	}
	p {
		color: #565d64;
		line-height: 1.6;
	}
	h2 {
		color: #022144;
		/*font-size: 1.2rem;*/
	}
	ul {
		list-style: decimal;
		color: #656e77;
	}
	padding-bottom: 20px;
}
</style>
